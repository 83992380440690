<template>
    <div class="alert alert-custom alert-indicator-left" :class="alertType" role="alert">
        <div class="alert-content">
            <span class="alert-title"> {{ alertTitle }} </span>
            <span class="alert-text"> {{ alertText }} </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MessageCard',
    props:['alertType', 'alertTitle', 'alertText'],
}
</script>

<style scoped>
    /* .alert-custom span {
        display: block;
        padding: 5px 0;
    } */
    .alert-custom .alert-content .alert-title {
        font-size: 1.125rem;
        font-weight: 500;
    }

    /* .alert-text {
        font-weight: 300;
    } */
</style>
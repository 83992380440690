<template>
    <div class="row">
        <div class="col-12">

            <!-- Message Card -->
            <MessageCard 
                :alertType="'indicator-info'" 
                :alertTitle="$t('claims.new.comments')" 
                :alertText="$t('evidences.card_comment')"
            />

            <!-- Table Heading-->
            <h1 class="table-heading"> {{ $t('evidences.awaiting_evidences')}} </h1>
          
            <!-- Loading -->
            <div v-show="loading" style="width: 100%; text-align: center;">
                <div class="spinner-border" style="height: 4rem; width: 4rem;" role="status">
                    <span class="visually-hidden">{{ $t('events.loading') }}...</span>
                </div>
            </div>

            <!-- Evidence Table  -->
            <table v-show="!loading" class="table table-borderless">
                <thead>
                    <tr>
                        <th scope="col"> {{ $t('claims.processing.reference-number').toUpperCase()}} </th>
                        <th scope="col"> {{ $t('reports.claim_type').toUpperCase()}} </th>
                        <th scope="col"> {{ $t('evidences.created').toUpperCase()}} </th>
                        <th scope="col"> {{ $t('events.filters.vehicle_registration').toUpperCase()}} </th>
                        <th scope="col"> {{ $t('evidences.ready_to_process').toUpperCase()}} </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="evidence in evidences" :key="'claim-' + evidence.id" @click="openClaim(evidence.id)" style="vertical-align: middle;" class="cur_pointer">
                        <td>{{ evidence.reference }}</td>
                        <td>
                            <span v-if="evidence.type === 'driveoff' || evidence.type === 'escalated' || evidence.type === 'cnafe'"> Drive Off </span>
                            <span v-if="evidence.type === 'nmop'"> No Means of Payment </span>
                            <span v-if="evidence.type === 'cnaf'">Customer not at Fault</span>
                        </td>
                        <td :class="{'text-danger': (evidence.date_received !== null && evidence.receivedDaysAgo > 14), 'text-warning': (evidence.date_received !== null && evidence.receivedDaysAgo > 7 && evidence.receivedDaysAgo < 15)}">
                            {{ `${evidence.date_received} (${evidence.receivedDaysAgo} days ago)`}}
                        </td>
                        <td>
                            <span class="vrm bold number-plate-gbr">{{evidence.plate}}</span>
                        </td>
                        <td>
                            <i v-if="evidence.markedForReady" class="material-icons" style="color:#4BAD48;"> done </i>
                            <i v-else class="material-icons" style="color: #DC3545;"> close </i>
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- Banner Modal -->
            <div class="modal fade" id="awaitingEvidenceBanner" tabindex="-1" aria-labelledby="awaitingEvidenceBannerLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="awaitingEvidenceBannerLabel">Awaiting Evidence</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p>We noticed you have some claims that need evidence uploading. Please take some time to go through them and upload the requested documents. Without these documents our chances at recovery are much smaller so it's important to make sure they are uploaded before your CCTV runs out.</p>
                        </div>
                    </div>
                </div>
            </div>
                        
        </div>
    </div>
</template>

<script>
//Components
import MessageCard from '../../../components/MessageCard.vue';
import {Form, Field, ErrorMessage} from 'vee-validate';

//Dependencies 
import * as yup from 'yup';
import EvidencesService from '../../../services/evidences.service';

export default {
    name: 'Evidence',
    components: { MessageCard, Form, Field, ErrorMessage },
    props: {
        showBanner: {
            type: Boolean,
            default: false
        }
    },
    data() {
        //reference, type, date, reg num 
        const schema = yup.object().shape({
            reference:yup.string(),
            claimType:yup.string(),
            timestamp:yup.string().matches('/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}/i'),
            registration:yup.string().min(3, 'Must be at least 3 characters!').max(12, 'Must not be longer than 12 characters!'),
        });
        return {
            loading: true,
            evidences: [],
            schema,
            currentParams: {},
            attempt: 1,
            timer: 10
        }
    },
    mounted() {
        this.loadEvidences();
        if(this.showBanner){

            let modal = new bootstrap.Modal(document.getElementById('awaitingEvidenceBanner'), {
                keyboard: false
            });

            modal.show();

        }
    },
    methods: {
        loadEvidences(params = {}) {
            this.loading = true;
            this.currentParams = params;
            EvidencesService.getEvidences(this.$store.state.auth.user.selectedClient, params).then((response) => {
                // console.log(response);
                this.evidences = response.data.claims;
                this.loading = false;
            })
            .catch(error => {
                console.log(error);
                this.loading = false;
            })
        },
        openClaim(id) {
            this.$router.push(`/evidence/${id}`);
        },
        clearFilter(params = {}) {
            this.loadEvidences(params);
            this.$refs.form.resetForm();
        }
    }
}
</script>

<style scoped>
    /* .table {
        background-color: #232330;
    } */

    .status-container {
        padding: 5px;
        border: 1px solid var(--utility-orange);
    }
</style>


